import Vue from 'vue';
import App from './App.vue';
import {ApolloClient} from 'apollo-client';
import {HttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
//import {setContext} from 'apollo-link-context';
import {GraphQLClient} from 'graphql-request';
import vueCustomElement from 'vue-custom-element';
Vue.use(vueCustomElement);
/*
import vuetify from './plugins/vuetify';
import css from 'vuetify/dist/vuetify.min.css';
*/

Vue.config.productionTip = false

// global variables
let global = {};
global.graphqlEndpoint = null;
if (!global.graphqlEndpoint) {
  
  let url = window.location;
  console.log('url host ' + url.host);
  if ( (url.host.match(/localhost/)) || (url.host.match(/127.0/))) {
    global.graphqlEndpoint = 'http://localhost:8000/graphql';
    global.apiEndPoint = 'http://localhost:8000/api';
  } else {
    global.graphqlEndpoint = 'https://internal.callerip.ca:8000/graphql';
    global.apiEndPoint = 'https://internal.callerip.ca:8000/';
  }
  
 //global.server = window.location.protocol + '//' + window.location.hostname + ':8000'
  //global.graphqlEndpoint = global.server + '/graphql';
  //global.apiEndPoint = global.server + '/api';
}


// HTTP connexion to the API
const httpLink = new HttpLink({
    // You should use an absolute URL here
    uri: global.graphqlEndpoint,
  });
  
    // Cache implementation
    const cache = new InMemoryCache()
  
  /*
  const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
        }
      }
  });
  */

  // Create the apollo client
  const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
    connectToDevTools: true,
  });
  global.apolloClient = apolloClient;
  
  const gqlClientFunction = function() {
    // console.log('LEVON erkljhdlkfjdsaf');
    // console.log(global.click2call_token);
    return new GraphQLClient(global.graphqlEndpoint, {
      headers: {
      },
    });
  };
  
  
  global.gqlClient = gqlClientFunction;
  global.gql = gql;
  Vue.config.productionTip = false
  Vue.prototype.$global = global;
  

//App.store = store;
//App.router = router;
Vue.customElement('slogboard-form', App);
