<template>
  
  <div id="slogboard-form">
    <div v-if="!uuid">
      <p>You need to call slogbord form with a UUID to identify the form you want to display</p>
      <p>For example:</p>
      <p> &lt;slogboard-form uuid="111-aaa-111-aaa-111-aaa" title="Contact Us"&gt;&lt;/slogboard-form&gt;</p>
    </div>
    <div class="pa-0 mt-0" v-if="!submitted">
      <div id="form-render"></div>
      <button
        color="primary"
        v-on:click="submitForm();"
        :loading="loading"
        class="btn btn-primary"
      >
        Submit
      </button>
  </div>
  <div class="pa-0 mt-0" v-if="submitted">
        Thank you, the form has been submitted.<br><br>
        <div v-if="formSubmit.support_request_id">
          You can view the status of the request at <a :href="slogurl">slogboard.com</a>
        </div>
  </div>
  </div>
</template>

<script>
const gql = require('graphql-tag');
import Vue from 'vue';
import $ from 'jquery';
//import AWS from 'aws-sdk';
window.jQuery = $;
window.$ = $;
require('jquery-ui-sortable');
require('formBuilder');
require('formBuilder/dist/form-render.min.js');

let mounted = false;

const formQry = gql`
  query formQry($uuid: String!) {
    form(uuid: $uuid) {
      form_id
      name
      description
      lane_id
      form_src
      uuid
      AvailableLanes {
        lane_id
        name
      }
    }
  }`;

const submitFormMutation = `
  mutation formSubmitMutation($input: FormSubmitInput) {
    formSubmit(input: $input) {
      form_submit_id
      form_id
      support_request_id
      data
      created
      support_request_uuid
      reported_by
      reported_by_email
      ticket
    }
  }
`;

const s3PutUrlMutation = `
  mutation s3PutUrlMutation($input: S3ParamsInput) {
    s3PutUrl(input: $input) {
      content_type
      key
      upload_url
    }
  }
`;

const addSlogNoteAttachmentMutation = `
  mutation noteAttachmentAddMutation($input: NoteAttachmentInput) {
    noteAttachmentAdd(input: $input) {
        note_attachment_id
        note_id
        s3_key
        s3_bucket
        s3_region
    }
  }
`;

const addSlogNoteMutation = `
  mutation slogNoteFromEmailMutation($input: NoteInput) {
    slogNoteFromEmail(input: $input) {
        note_id
        related_entity_id
    }
  }
`;

export default {
  name: 'FormView',
  props: {
    uuid: { 
      required: true,
    },
  },
  components: {
  },
  data:
  function() {
    return {
      currentForm: {name: null, lane_id: null, description: null, AvailableLanes: []},
      test: 'hello',
      fRender: null,
      submitted: false,
      formSubmit: null,
      slogurl: null,
      loading: false,
      testvar: 'hello',
    };
  },
  computed: {
    
  },
  methods: {
    goback: function() {
      this.$router.go(-1);
    },
     getForm: async function(uuid) {
        
        console.log('getting form')
        let variables = {
          uuid: uuid,
        }
        const apolloClient = this.$global.apolloClient;
        let result = await apolloClient.query({query: formQry, variables, fetchPolicy: 'network-only'});
        if (result) {
          this.currentForm = result.data.form;
          this.currentForm.lane_id = result.data.form.lane_id;
          Vue.set(this.currentForm,'lane_id', result.data.form.lane_id);
          Vue.set(this.currentForm,'AvailableLanes', result.data.form.AvailableLanes);
          console.log(this.currentForm);
        }
        return this.currentForm;
      },
      renderForm: async function() {
        console.log('rendering form');
        this.fRender = await $('#form-render').formRender({formData: this.currentForm.form_src});
        console.log(this.fRender.userData);
      },
      submitForm: async function() {
        console.log('submit form');
        console.log(this.fRender.userData);
        console.log(this.fRender);
        //console.log(JSON.stringify(this.fRender.userData));
        let variables = {
          input: {
            form_id: parseInt(this.currentForm.form_id),
            data: JSON.stringify(this.fRender.userData),
          }
        };
        const gqlClient = this.$global.gqlClient();
        this.loading = true;
        let result = await gqlClient.request(submitFormMutation, variables);
        console.log(result);
        this.formSubmit = result.formSubmit;
        this.slogurl = "https://www.slogboard.com/#/workitem/" + this.formSubmit.support_request_uuid;

        let reportedByEmail = this.formSubmit.reported_by_email;
        let reportedBy = this.formSubmit.reported_by;
        let ticket = this.formSubmit.ticket;

        // check for file uploads, if there is one or more, 
        // 1. create a note
        // 2. get a URL to upload to S3 bucket
        // 3. then create note attachments for that note referencing the s3 file
        let userData = this.fRender.userData;
        if (! await this.fileUploadExists(userData)) {
          // no files, our work is done.
          this.submitted = true;
          return result;
        }

        // files exist, lets do the work described above.

        // first, make a note
        let note = await this.createNote(
          "Form attachments",
          reportedByEmail,
          reportedBy,
          ticket
        );
        console.log(note);

        // for each file, get an S3 upload URL, upload the file, add the note attachment
        let files = await this.getFormFiles(userData);
        console.log('files is ');
        console.log(files);
        for (let i=0;i<files.length;i++) {
          let file = files[i];
              
          // get the s3 upload URL
          let variables = {
            input: {
              content_type: file.type,
            }
          };
          const gqlClient = this.$global.gqlClient();
          this.loading = true;
          let result = await gqlClient.request(s3PutUrlMutation, variables);
          console.log('s3PutUrl result');
          console.log(result);
          let s3PutUrl = result.s3PutUrl;
          console.log(result);

          // upload the file
          let s3result = await fetch(result.s3PutUrl.upload_url, {
            method: 'PUT',
            body: file
          });
          console.log(s3result);
          if (s3result.status != 200) {
            alert("File upload was not successful");
            continue;
          }
          
          // create a note attachment (which references the s3 file)
          console.log(addSlogNoteAttachmentMutation)
          variables = {
              input: {
                  note_id: parseInt(note.note_id),
                  //s3_region: s3Region,  CLIENT DOESN'T KNOW THESE
                  //s3_bucket: slogboardAttachmentBucket,
                  s3_key: s3PutUrl.key,
                  original_filename: file.name,
                  content_type: file.type,
                }    
          };
          result = await gqlClient.request(addSlogNoteAttachmentMutation, variables);
          console.log('attachment for note result');
          console.log(result);

          
        }
        
        this.submitted = true;
      },
      getFormFiles: async function(userData) {
        let files = [];
        for (let i=0;i<userData.length;i++) {
          let field = userData[i];
          if (field.type == 'file') {
            console.log('found ' + field.name);
            let fileEl = document.getElementById(field.name);
            console.log('file Element is ');
            console.log(fileEl);
            console.log(fileEl.files);
            for (let j=0;j<fileEl.files.length;j++) {
              let file = fileEl.files[j];
              files.push(file);
            }
          }
        }
        return files;
      },
      fileUploadExists: async function(userData) {
        for (let i=0;i<userData.length;i++) {
          let field = userData[i];
          if (field.type == 'file') {
            let fileEl = document.getElementById(field.name);
            console.log(fileEl.files);
            if (fileEl.files.length > 0) {
              return true;
            }
          }
        }
        return false;
      },
      async createNote(text, from, fromName, ticket, withholdNotification) {
        let variables = {
                input: {
                        textnote: text,
                        created_by_email: from,
                        created_by_name: fromName,
                        slog_ticket: ticket,
                        withhold_notification: withholdNotification,
                  }    
            };
        const gqlClient = this.$global.gqlClient();
        let result = await gqlClient.request(addSlogNoteMutation, variables);
        console.log('note');
        console.log(result);
        let note = result.slogNoteFromEmail;
        return note;
      },
  }, 
  async mounted() {

      // await this.$store.state.applicationLoadPromise;
      console.log('mounted');
      if (mounted == false) {
        if (this.uuid) {
          console.log(this.uuid);
          await this.getForm(this.uuid);
          console.log('got form, now renderForm()');
          await this.renderForm();
          mounted = true;
        }
      }
  },
  watch: {

  },
  filters: {

  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
